import { Injectable } from "@angular/core";
import { Constants } from "@core/constants";
import { Plan } from "@core/domain/plan";
import { Period, PlanDetails, StudyYear } from "@core/domain/plan-details";
import { Specialisation } from "@core/domain/plan-later";
import { MinorProfile } from "@core/domain/plan-profile";
import { CaciUtil } from "@core/util/caci-util";
import { TranslateService } from "@ngx-translate/core";
import { CourseTest } from "../course-test";

@Injectable({
  providedIn: "root",
})
export class PlanHelper {
  constructor(private translate: TranslateService) {}

  getPlanType(plan: Plan): string {
    return this.translate.instant(plan.draft ? "label.draft" : "label.plan");
  }

  getPlanTitle(plan: Plan): string {
    return `${this.getPlanType(plan)}: ${plan.name}`;
  }

  getPlanYear(plan: PlanDetails, yearNr: number): StudyYear {
    return plan.studyYears.filter((year) => year.yearNr === yearNr)[0];
  }

  getPlanningYears(plan: PlanDetails): number[] {
    const planningYears = plan.studyYears
      .filter((year) => year.academicYear >= plan.currentYear)
      .map((year) => year.academicYear);

    if (plan.currentYear === Math.max(...planningYears)) {
      planningYears.push(plan.currentYear + 1);
    }

    return planningYears;
  }

  getPastYears(plan: PlanDetails): number[] {
    return plan.studyYears.filter((year) => year.academicYear < plan.currentYear).map((year) => year.academicYear);
  }

  getPlanPeriod(periods: Period[], periodNr: number): Period {
    return periods.filter((period) => period.periodNr === periodNr)[0];
  }

  isUploadPossible(plan: PlanDetails): boolean {
    return plan?.documentToUpload !== Constants.OPTION_NO ?? false;
  }

  hasReadOnlyStatus(plan: PlanDetails): boolean {
    // prettier-ignore
    return (!CaciUtil.isNullOrEmpty(plan.planState) &&
      (plan.planState === Constants.PLAN_STATUS_SUBMITTED_FOR_APPROVAL ||
      (plan.planState === Constants.PLAN_STATUS_APPROVED && plan.reviewMoment === Constants.REVIEW_MOMENT_FINAL))
    );
  }

  isSpecialisationPlanned(specialisation: Specialisation): boolean {
    return specialisation?.planningState === Constants.PLANNING_STATE_PLANNED || specialisation?.planningState === "";
  }

  getMinorExamType(type: string, minimumPoints: number | undefined): string | undefined {
    switch (type) {
      case Constants.EXAM_COMPONENT_TYPE_MANDATORY:
        return this.translate.instant("examPart.examTypeMandatory");
      case Constants.EXAM_COMPONENT_TYPE_MANDATORY_CHOICE:
        return this.translate.instant("examPart.examTypeMandatoryChoice", {
          minimumPoints,
        });
      case Constants.EXAM_COMPONENT_TYPE_CHOICE:
        return this.translate.instant("examPart.examTypeChoice");
      default:
        return undefined;
    }
  }

  specialisationEquals(specialisation1: Specialisation, specialisation2: Specialisation): boolean {
    return (
      specialisation1.name === specialisation2.name &&
      specialisation1.code === specialisation2.code &&
      specialisation1.studyProgramCode === specialisation2.studyProgramCode
    );
  }

  minorEquals(minor1: MinorProfile, minor2: MinorProfile): boolean {
    return minor1.minor === minor2.minor && minor1.name === minor2.name && minor1.type === minor2.type;
  }

  /**
   * Only show courses according to the following algorithm:
   * User is allowed to see courses with the same or lower examenfase.
   * ExamStage priority:
   * - 1e-jaar-bachelor
   * - associate-degree
   * - bachelor
   * - pre-master
   * - master
   * - empty (always shown)
   *
   * NOTE: If tweede_examenfase is set, it is leading.
   */
  determineExamStages(plan: PlanDetails): string[] {
    const examPhases = [] as string[];
    if (plan !== undefined) {
      examPhases.push(...["master", "pre-master", "bachelor", "associate-degree", "1e-jaar-bachelor", ""]);
      const examStage = plan.secondExamStage && plan.secondExamStage.length > 0 ? plan.secondExamStage : plan.examStage;

      switch (examStage) {
        case "master":
          // use set of examStages which is already created
          break;
        case "pre-master":
          examPhases.splice(0, 1);
          break;
        case "bachelor":
          examPhases.splice(0, 2);
          break;
        case "associate-degree":
          examPhases.splice(0, 3);
          break;
        case "1e-jaar-bachelor":
          examPhases.splice(0, 4);
          break;
        default:
          // console.log(`No valid examenfase found: ${examStage}`);
          examPhases.splice(0, 5);
          break;
      }
    } else {
      examPhases.push("");
    }

    return examPhases;
  }

  determineExamTypeCode(plan: PlanDetails): string[] {
    const examentypeCode = [] as string[];
    if (plan !== undefined) {
      if (plan.secondExamTypeCode && plan.secondExamTypeCode.length > 0) {
        examentypeCode.push(plan.secondExamTypeCode);
      } else if (plan.examTypeCode !== undefined && plan.examTypeCode.length > 0) {
        examentypeCode.push(plan.examTypeCode);
      }
    }

    return examentypeCode;
  }

  calculatePointsPlanned(plan: PlanDetails | undefined): number {
    if (!plan) {
      return 0;
    }

    // Only count the non ribbon courses, to prevent doubling points for a ribbon course
    return plan.studyYears
      .flatMap((year) => year.periods)
      .flatMap((period) => period.courses)
      .filter((course) => !course.isRibbon)
      .reduce((total, course) => total + course.studyPoints, 0);
  }

  stripSpaces(text: string): string {
    return text?.replace(/\s/g, "");
  }

  addCourseTests(period: Period): void {
    if (period.courseTests && period.courseTests.length > 0) {
      const testsPerCourseCode: {
        [courseCode: string]: { courseName: string; courseTests: CourseTest[] };
      } = {};

      period.courseTests.forEach((courseTest) => {
        const courseCode = courseTest.courseCode;
        const courseName = courseTest.courseName;
        if (testsPerCourseCode[courseCode]) {
          testsPerCourseCode[courseCode].courseTests.push(courseTest);
        } else {
          testsPerCourseCode[courseCode] = { courseName: courseName, courseTests: [courseTest] };
        }
      });

      // Sort the courseTests for each courseCode by testDescription
      Object.keys(testsPerCourseCode).forEach((courseCode) => {
        testsPerCourseCode[courseCode].courseTests.sort((a, b) => a.testDescription.localeCompare(b.testDescription));
      });

      period.courses.forEach((course) => {
        const courseCode = course.code;
        if (testsPerCourseCode[courseCode]) {
          // add test list to course object
          course.courseTests = testsPerCourseCode[courseCode].courseTests;
          // remove from testsPerCourseCode, so that the tests for this course are not added as courseTestBlocks
          delete testsPerCourseCode[courseCode];
        }
      });

      // the remaining tests, for courses that don't occur in this period, are added as courseTestBlocks
      period.courseTestBlocks = Object.keys(testsPerCourseCode)
        .map((courseCode) => ({
          courseCode,
          courseName: testsPerCourseCode[courseCode].courseName,
          courseTests: testsPerCourseCode[courseCode].courseTests,
        }))
        .sort((a, b) => a.courseCode.localeCompare(b.courseCode));
    }
  }

  refreshCourseTests(period: Period): void {
    period.courses.forEach((course) => {
      course.courseTests = [];
    });
    period.courseTestBlocks = [];
    this.addCourseTests(period);
  }
}
