<ng-container>
  <ion-list>
    @if (showCourseInfo) {
      <ion-item [button]="true" [detail]="false" (click)="onAction('course-info')">
        <ion-icon icon="information-circle-outline" class="course-action" slot="start" />
        {{ "courseControl.showCourseInfo" | translate }}
      </ion-item>
    }

    @if (showTestInfo) {
      <ion-item [button]="true" [detail]="false" (click)="onAction('test-info')">
        <ion-icon icon="information-circle-outline" class="course-action" slot="start" />
        {{ "courseControl.showTestInfo" | translate }}
      </ion-item>
    }

    <ng-template [ngxPermissionsExcept]="Constants.ROLE_READONLY_EMPLOYEE">
      @if (showPlanNow) {
        <ion-item [button]="true" [detail]="false" (click)="onAction('plan')">
          <ion-icon icon="calendar" class="course-action" slot="start" />
          {{ "label.planNow" | translate }}
        </ion-item>
      }

      @if (showMove) {
        <ion-item [button]="true" [detail]="false" (click)="onAction('move')">
          <ion-icon icon="move" class="course-action" slot="start" />
          {{ "courseControl.move" | translate }}
        </ion-item>
      }

      @if (showRemove) {
        <ion-item [button]="true" [detail]="false" (click)="onAction('remove')">
          <ion-icon icon="trash-outline" class="course-action" slot="start" />
          {{ "label.remove" | translate }}
        </ion-item>
      }
    </ng-template>
  </ion-list>
</ng-container>
