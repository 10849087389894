import { booleanAttribute, Component, inject, Input, OnInit } from "@angular/core";
import { Constants } from "@core/constants";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { CourseActionMenuComponent } from "@shared/components/course-action-menu/course-action-menu.component";
import { ModalService } from "@shared/services/modal.service";
import { PlanActionService } from "@shared/services/plan-action.service";
import { CourseData } from "@shared/types/course-data";
import { CourseAction } from "@shared/types/plan-actions";

@Component({
  selector: "pa-course-item",
  templateUrl: "./course-item.component.html",
  styleUrls: ["./course-item.component.scss"],
})
export class CourseItemComponent implements OnInit {
  @Input() courseData!: CourseData;
  @Input({ transform: booleanAttribute }) showActions = false;
  @Input({ transform: booleanAttribute }) showCategory = false;
  @Input({ transform: booleanAttribute }) hideTimeslots = false;
  @Input({ transform: booleanAttribute }) hideShowInfo = false;
  @Input({ transform: booleanAttribute }) isScheduleSelectable = false;
  @Input() showPlanLaterButton = false;
  @Input() viewStartedBy!: string;

  courseHelper = inject(CourseHelper);
  hoverItem = false;
  itemId = `item-${Math.random().toString(36).substring(7)}`;

  protected readonly Constants = Constants;
  private planActionService = inject(PlanActionService);
  private modalService = inject(ModalService);
  private popoverController = inject(PopoverController);
  private translateService = inject(TranslateService);
  courseObtainedTooltip = "";

  ngOnInit(): void {
    if (this.courseData.statusObtained !== undefined) {
      this.courseObtainedTooltip = this.translateService.instant(
        this.courseHelper.getStatusObtainedLabel(this.courseData),
      );
    }
  }

  async onShowActionMenu(event: MouseEvent): Promise<void> {
    if (!this.showActions) {
      return;
    }

    const popoverProps = {
      component: CourseActionMenuComponent,
      componentProps: {
        course: this.courseData,
        showRemove: this.courseData.isRemovable,
        showCourseInfo: !this.courseData.isCustomCourse,
        showPlanNow: this.courseData.isPlannable,
        showToPlanLater: this.showPlanLaterButton && !this.courseData.isPartOfPlanLater,
      },
      backdropDismiss: true,
      event: event,
    };

    // prettier-ignore
    const popover = await this.popoverController.create(popoverProps);
    await popover.present();

    popover.onWillDismiss().then((result) => {
      if (result?.data) {
        switch (result.data as CourseAction) {
          case "remove":
            this.onRemoveCourse();
            break;
          case "course-info":
            this.onCourseInfo();
            break;
          case "plan":
            this.onPlanNow();
            break;
          case "later":
            this.onPlanLater();
            break;
        }
      }
    });
  }

  onPlanNow(event?: MouseEvent): void {
    event && event.stopPropagation();

    this.planActionService.planNow(this.courseData);
  }

  onPlanLater(event?: MouseEvent): void {
    event && event.stopPropagation();
    this.courseData = this.planActionService.planLater(this.courseData);
  }

  onRemoveCourse(event?: MouseEvent): void {
    event && event.stopPropagation();
    this.planActionService.removeCourseFromPlanLater(this.courseData);
  }

  async onCourseInfo(event?: MouseEvent): Promise<void> {
    event && event.stopPropagation();
    this.modalService.showCourseInfoModal(this.courseData, this.isScheduleSelectable, this.viewStartedBy).subscribe();
  }

  determineCourseSideColor(): string {
    if (this.courseData.courseColor) {
      return this.courseData.courseColor;
    }
    if (this.hoverItem) {
      return "var(--pa-color-action-hover)";
    }

    return this.courseData.yearNr > 0 && this.courseData.periodDescription !== ""
      ? "var(--pa-grid-color-course-obtained)"
      : "white";
  }
}
