import { booleanAttribute, Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { CourseAcademicYear, CourseDetail, CoursePeriod } from "@core/domain/course-detail";
import { AuthService } from "@core/services/auth.service";
import { SelectedPeriod } from "@shared/types/course-data";

@Component({
  selector: "pa-plan-element",
  templateUrl: "./plan-element.component.html",
  styleUrls: ["./plan-element.component.scss"],
})
export class PlanElementComponent implements OnInit {
  @Input() label?: string;
  @Input() courseDetail!: CourseDetail;
  @Input({ transform: booleanAttribute }) isScheduleSelectable = false;
  @Output() selectedPeriod: EventEmitter<SelectedPeriod> = new EventEmitter();

  textCancel = "label.cancel";
  private authService = inject(AuthService);
  timeSlotLabel = "";

  ngOnInit(): void {
    document.documentElement.style.setProperty(
      "--element-period-columns",
      (this.courseDetail?.studyYear[0].periods.length + 1).toString(),
    );

    if (this.authService.isRoleReadOnlyEmployee()) {
      this.isScheduleSelectable = false;
    }
  }

  clickOnPeriod(year: CourseAcademicYear, period: CoursePeriod): void {
    if (this.isScheduleSelectable && period.offered) {
      this.selectPeriod(year, period);
    }
  }

  private selectPeriod(year: CourseAcademicYear, period: CoursePeriod): void {
    if (this.isScheduleSelectable) {
      this.selectedPeriod.emit({ year: year, period: period });
    }
  }
}
