import { computed, effect, inject, Injectable, signal } from "@angular/core";
import { Constants } from "@core/constants";
import { Course } from "@core/domain/course";
import { ExamComponent } from "@core/domain/exam-component";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { PlanHelper } from "@core/domain/helpers/plan-helper";
import { PlanDetails } from "@core/domain/plan-details";
import { PlanLater, Specialisation } from "@core/domain/plan-later";
import { MinorProfile } from "@core/domain/plan-profile";
import { PlanLaterCourseGroupBuilder } from "@feature/plan-later/data/plan-later-course-group.builder";
import { PlanStateService } from "@shared/services/plan-state.service";
import { CourseData, CourseDataGroup, SelectedPeriod } from "@shared/types/course-data";
import { PlanLaterType } from "@shared/types/plan-later-types";

@Injectable({
  providedIn: "root",
})
export class PlanLaterStateService {
  protected readonly Constants = Constants;

  private courseGroupBuilder: PlanLaterCourseGroupBuilder;

  private courseHelper = inject(CourseHelper);
  private planHelper = inject(PlanHelper);
  planStateService = inject(PlanStateService);

  minorCourses = signal<CourseDataGroup[]>([]);
  specialisationCourses = signal<CourseDataGroup[]>([]);

  mandatoryGroups = computed<CourseDataGroup[]>(() => {
    const selectedPlan = this.planStateService.currentSelectedPlan();
    const planLater = this.planStateService.planLater();

    // prettier-ignore
    return planLater?.mandatory ? planLater.mandatory
      .filter(examComponent => !examComponent.conditionsMetMandatoryOrMandatoryChoice) 
      .map(examComponent => this.courseGroupBuilder.buildExamComponentCourseGroup(examComponent, PlanLaterType.MANDATORY, selectedPlan))
      : []
  });

  mandatoryChoiceGroups = computed<CourseDataGroup[]>(() => {
    const selectedPlan = this.planStateService.currentSelectedPlan();
    const planLater = this.planStateService.planLater();

    // prettier-ignore
    return planLater?.mandatoryChoice ? planLater.mandatoryChoice
      .filter(examComponent => !examComponent.conditionsMetMandatoryOrMandatoryChoice)
      .map(examComponent => this.courseGroupBuilder.buildExamComponentCourseGroup(examComponent, PlanLaterType.MANDATORY_CHOICE, selectedPlan))
      : [];
  });

  readonly specialisationGroups = computed<CourseDataGroup[]>(() => {
    const selectedPlan = this.planStateService.currentSelectedPlan();
    const planLater = this.planStateService.planLater();
    const specialisationCourseGroup = this.specialisationCourses();

    const specialisationGroup = planLater?.specialisation
      ? planLater.specialisation.specialisations
          .filter((specialisation) => specialisation.examComponents && specialisation.examComponents.length > 0)
          .map((specialisation) => {
            const examComponents = specialisation.examComponents.filter(
              (examComponent) => examComponent.courses && examComponent.courses.length > 0,
            );
            return this.courseGroupBuilder.buildSpecialisationCourseGroup(
              specialisation,
              examComponents,
              selectedPlan,
              this,
            );
          })
      : [];

    if (!specialisationGroup) {
      this.specialisationCourses.set(specialisationCourseGroup);
    }

    // Check if the added specialisation is already in the planLater specialisationGroup
    specialisationCourseGroup.forEach((specialisationCourse) => {
      if (!specialisationGroup.some((specialisation) => specialisation.title === specialisationCourse.title)) {
        specialisationGroup.push(specialisationCourse);
      }
    });

    return specialisationGroup;
  });

  showProfile(): boolean {
    return (
      this.planStateService.currentSelectedPlan() === undefined ||
      this.planStateService.currentSelectedPlan()?.planState !== Constants.PLAN_STATUS_APPROVED
    );
  }

  readonly profilingMinorGroup = computed<CourseDataGroup[]>(() => {
    const selectedPlan = this.planStateService.currentSelectedPlan();
    const planLater = this.planStateService.planLater();
    const minorCourses = this.minorCourses();

    if (!planLater?.profile) {
      return [];
    }

    // prettier-ignore
    const profilingMinorGroup = planLater?.profile.minors
      ? planLater.profile.minors
        .filter(minor => minor.examComponents && minor.examComponents.length > 0)
        .map(minor => {const examComponents = minor.examComponents
            .filter(examComponent => examComponent.courses && examComponent.courses.length > 0);
              return this.courseGroupBuilder.buildMinorCourseGroup(minor, examComponents, selectedPlan, this);
          })
     : [];

    if (!minorCourses) {
      this.minorCourses.set(profilingMinorGroup);
    }

    // Check if the added minorCourse is already in the planLater profileMinorGroup
    minorCourses.forEach((minorCourse) => {
      if (!profilingMinorGroup.some((profilingMinor) => profilingMinor.title === minorCourse.title)) {
        profilingMinorGroup.push(minorCourse);
      }
    });

    return profilingMinorGroup;
  });

  readonly profileCoursesGroup = computed<CourseDataGroup>(() => {
    const selectedPlan = this.planStateService.currentSelectedPlan();
    const planLater = this.planStateService.planLater();

    return this.courseGroupBuilder.buildCoursesGroup(
      planLater?.profile ? planLater.profile.examComponentName : "",
      undefined,
      undefined,
      planLater?.profile?.courses ?? [],
      PlanLaterType.PROFILE_COURSES,
      selectedPlan,
    );
  });

  readonly otherCoursesGroup = computed<CourseDataGroup>(() => {
    const selectedPlan = this.planStateService.currentSelectedPlan();
    const planLater = this.planStateService.planLater();

    return this.courseGroupBuilder.buildCoursesGroup(
      "planLater.otherCourses",
      undefined,
      undefined,
      planLater?.otherCourses ?? [],
      PlanLaterType.OTHER_COURSES,
      selectedPlan,
    );
  });

  readonly replacementsCoursesGroup = computed<CourseDataGroup>(() => {
    const selectedPlan = this.planStateService.currentSelectedPlan();
    const planLater = this.planStateService.planLater();

    return this.courseGroupBuilder.buildCoursesGroup(
      "planLater.replacements",
      undefined,
      undefined,
      planLater?.replacements ?? [],
      PlanLaterType.REPLACEMENT_COURSES,
      selectedPlan,
    );
  });

  constructor() {
    this.courseGroupBuilder = new PlanLaterCourseGroupBuilder();
    effect(
      () => {
        const plan = this.planStateService.currentSelectedPlan();
        if (plan) {
          this.updatePlanLaterWithSelectedPlanCourseData(plan);
        }
      },
      { allowSignalWrites: true },
    );
  }

  addCustomCourse(customCourse: Course): void {
    // Setting the updated planLater will trigger the effect to update the planLater
    this.planStateService.planLater.update((currentPlanLater) => {
      currentPlanLater!.otherCourses.push(customCourse);
      return { ...currentPlanLater } as PlanLater;
    });
  }

  addOtherCourse(otherCourse: Course): void {
    // Setting the updated planLater will trigger the effect to update the planLater
    this.planStateService.planLater.update((currentPlanLater) => {
      if (!currentPlanLater?.otherCourses.includes(otherCourse)) {
        currentPlanLater?.otherCourses.push(otherCourse);
        return { ...currentPlanLater } as PlanLater;
      }

      return currentPlanLater;
    });
  }

  updatePlanLaterWithUpdatedCourseData(updatedCourse: Course, selectedPeriod?: SelectedPeriod): void {
    // prettier-ignore
    this.planStateService.planLater.update(currentPlanLater => {
      const planLater = { ...currentPlanLater } as PlanLater;

      planLater?.mandatory
        ?.forEach(examComponent => this.updateCourses(examComponent, updatedCourse, selectedPeriod));
      planLater?.mandatoryChoice
        ?.forEach(examComponent => this.updateCourses(examComponent, updatedCourse, selectedPeriod));
      planLater?.specialisation?.specialisations
        ?.forEach(specialisation => specialisation.examComponents
            ?.forEach(examComponent => this.updateCourses(examComponent, updatedCourse, selectedPeriod)));
      planLater?.otherCourses
        ?.filter(course => this.courseHelper.isSameCourse(course, updatedCourse))
        .forEach(course => this.updateCourse(course, updatedCourse, selectedPeriod));
      planLater?.profile?.courses
        ?.filter(course => this.courseHelper.isSameCourse(course, updatedCourse))
        .forEach(course => this.updateCourse(course, updatedCourse, selectedPeriod));
      planLater?.profile?.minors
        ?.forEach(minor => minor.examComponents
            ?.forEach(examComponent => this.updateCourses(examComponent, updatedCourse, selectedPeriod)));
      planLater?.replacements
        ?.filter(course => this.courseHelper.isSameCourse(course, updatedCourse))
        .forEach(course => this.updateCourse(course, updatedCourse, selectedPeriod));

      return planLater;
    });
  }

  removeCourse(courseData: CourseData): void {
    courseData.isAddedToPlanLater = false;
    courseData.isPartOfPlanLater = false;
    this.planStateService.planLater.update((currentPlanLater) => {
      // prettier-ignore
      switch (courseData.planLaterType) {
        case PlanLaterType.MANDATORY:
          currentPlanLater?.mandatory
            .forEach(examComponent => {
              examComponent.courses = this.removeCourseFromCourses(examComponent.courses, courseData);
            });
          break;

        case PlanLaterType.MANDATORY_CHOICE:
          currentPlanLater?.mandatoryChoice.forEach(examComponent => {
            examComponent.courses = this.removeCourseFromCourses(examComponent.courses, courseData);
          });
          break;

        case PlanLaterType.SPECIALISATION:
          currentPlanLater?.specialisation?.specialisations.forEach(specialisation =>
            specialisation.examComponents.forEach(examComponent =>{
              examComponent.courses = this.removeCourseFromCourses(examComponent.courses, courseData);
            }));
          break;

        case PlanLaterType.OTHER_COURSES:
          currentPlanLater!.otherCourses = this.removeCourseFromCourses(currentPlanLater!.otherCourses, courseData);
          break;

        case PlanLaterType.PROFILE_COURSES:
          currentPlanLater!.profile.courses = this.removeCourseFromCourses(currentPlanLater!.profile.courses, courseData);
          break;

        case PlanLaterType.PROFILE_MINOR:
          currentPlanLater?.profile.minors.forEach(minor =>
            minor.examComponents.forEach(examComponent => {
              examComponent.courses = this.removeCourseFromCourses(examComponent.courses, courseData);
            }));
          break;

        case PlanLaterType.REPLACEMENT_COURSES:
          currentPlanLater!.replacements = this.removeCourseFromCourses(currentPlanLater!.replacements, courseData);
          break;
      }

      return { ...currentPlanLater } as PlanLater;
    });

    this.planStateService.markPlanAsModified(true);
  }

  removeSpecialisation(specialisation: Specialisation): void {
    this.planStateService.planLater.update((currentPlanLater) => {
      if (currentPlanLater?.specialisation) {
        // prettier-ignore
        currentPlanLater.specialisation.specialisations = currentPlanLater.specialisation.specialisations
          .filter(spec => !this.planHelper.specialisationEquals(spec, specialisation));
        return { ...currentPlanLater };
      }

      return currentPlanLater;
    });

    this.planStateService.markPlanAsModified(true);
  }

  removeMinor(minor: MinorProfile): void {
    this.planStateService.planLater.update((currentPlanLater) => {
      if (currentPlanLater?.profile.minors) {
        // prettier-ignore
        currentPlanLater.profile.minors = currentPlanLater.profile.minors
          .filter(mnr => !this.planHelper.minorEquals(mnr, minor));

        return { ...currentPlanLater };
      }

      return currentPlanLater;
    });

    this.planStateService.markPlanAsModified(true);
  }

  addMinorCourseToPlanLater(minorToAdd: MinorProfile): void {
    // Update planLater with this minorHit
    this.planStateService.planLater.update((currentPlanLater) => {
      // prettier-ignore
      const planLaterHasMinor = currentPlanLater?.profile.minors
        .some(mr => mr.studyProgram === minorToAdd.studyProgram && mr.name === minorToAdd.name);
      if (!planLaterHasMinor) {
        currentPlanLater?.profile.minors.push(minorToAdd);

        return { ...currentPlanLater } as PlanLater;
      }

      return currentPlanLater;
    });

    this.planStateService.markPlanAsModified(true);
  }

  addSpecialisationCourseToPlanLater(specialisationToAdd?: Specialisation): void {
    if (specialisationToAdd === undefined) {
      console.warn("Specialisation not found in specialisationCourses! Should not be possible!");
      return;
    }

    // Update planLater with this specialisationHit
    this.planStateService.planLater.update((currentPlanLater) => {
      // prettier-ignore
      const planLaterHasSpecialisation = currentPlanLater?.specialisation?.specialisations
        .some(spec => spec.code === specialisationToAdd.code);

      if (!planLaterHasSpecialisation) {
        currentPlanLater?.specialisation?.specialisations.push(specialisationToAdd);
        return { ...currentPlanLater } as PlanLater;
      }

      return currentPlanLater;
    });

    this.planStateService.markPlanAsModified(true);
  }

  private updatePlanLaterWithSelectedPlanCourseData(selectedPlan: PlanDetails): void {
    // prettier-ignore
    this.planStateService.planLater.update(currentPlanLater => {
      // prettier-ignore
      currentPlanLater?.mandatory
        ?.forEach(examComponent => examComponent.courses
          .forEach(course => this.courseHelper.updateCoursePeriodInfo(course, selectedPlan)));

      // prettier-ignore
      currentPlanLater?.mandatoryChoice
        ?.forEach(examComponent => examComponent.courses
            .forEach(course => this.courseHelper.updateCoursePeriodInfo(course, selectedPlan)));

      // prettier-ignore
      currentPlanLater?.specialisation?.specialisations
        .forEach(specialisation => specialisation.examComponents
            .forEach(examComponent => examComponent.courses
                .forEach(course => this.courseHelper.updateCoursePeriodInfo(course, selectedPlan))));

      // prettier-ignore
      currentPlanLater?.otherCourses
        ?.forEach(course => this.courseHelper.updateCoursePeriodInfo(course, selectedPlan));

      // prettier-ignore
      currentPlanLater?.profile?.courses
        .forEach(course => this.courseHelper.updateCoursePeriodInfo(course, selectedPlan));

      // prettier-ignore
      currentPlanLater?.profile?.minors
        ?.forEach(minor => minor.examComponents
          ?.forEach(examComponent => examComponent.courses?.forEach(course => this.courseHelper.updateCoursePeriodInfo(course, selectedPlan))));

      // prettier-ignore
      currentPlanLater?.replacements
        ?.forEach(course => this.courseHelper.updateCoursePeriodInfo(course, selectedPlan));

      return { ...currentPlanLater } as PlanLater;
    });
  }

  findMinorInPlanLater(minorCode: string, minorStudyProgram: string): MinorProfile | undefined {
    // prettier-ignore
    return this.planStateService.planLater()?.profile.minors
      .find(minor => minor.minor === minorCode && minor.studyProgram === minorStudyProgram);
  }

  findSpecialisationInPlanLater(specialisationCode: string, studyProgramCode: string): Specialisation | undefined {
    // prettier-ignore
    return this.planStateService.planLater()?.specialisation?.specialisations
      .find(spec => {
        return spec.code === specialisationCode && spec.studyProgramCode === studyProgramCode;
      });
  }

  findSpecialisationWithSameNameDifferentProgramInPlanLater(
    specialisation: string,
    program: string,
  ): Specialisation | undefined {
    // Check if specialisation with same name but different specialisation program is already in PlanLater
    return this.planStateService.planLater()?.specialisation?.specialisations.find((spec) => {
      return spec.code === specialisation && spec.studyProgramCode !== program;
    });
  }

  minorCourseGroupRegistered(courseDataGroupName: string): boolean {
    // prettier-ignore
    return this.planStateService.planLater()?.profile.minors
      .some (minor => minor.name === courseDataGroupName && minor.statusRegistered ) ?? false;
  }

  getPlanLater(): PlanLater | undefined {
    return this.planStateService.planLater();
  }

  findCourseInPlanLater(courseCode: string): Course | undefined {
    const planLater = this.getPlanLater();
    if (!planLater) {
      return undefined;
    }

    // For the mandatory and mandatory choice, search in the groups.
    // The course may be present in the planLater object, but not displayed,
    // because it was filtered out in the groups.
    const mandatoryCourse = this.mandatoryGroups()
      ?.flatMap((courseDataGroup) => courseDataGroup.courseList)
      ?.flatMap((courseDataList) => courseDataList.courseData)
      .find((courseData: CourseData) => courseData.code === courseCode);
    const mandatoryChoiceCourse = this.mandatoryChoiceGroups()
      ?.flatMap((courseDataGroup) => courseDataGroup.courseList)
      ?.flatMap((courseDataList) => courseDataList.courseData)
      .find((courseData: CourseData) => courseData.code === courseCode);

    // For the other plan later sections, search in the planLater object.
    const specialisationCourse = planLater.specialisation?.specialisations
      .flatMap((specialisation) => specialisation.examComponents)
      .flatMap((examComponent) => examComponent.courses)
      .find((course) => course.code === courseCode);
    const existingOtherCourse = planLater.otherCourses?.find((course) => course.code === courseCode);
    let profileCourse;
    let minorCourse;
    if (this.showProfile()) {
      profileCourse = planLater.profile?.courses
        ? planLater.profile?.courses.find((course) => course.code === courseCode)
        : undefined;
      minorCourse = planLater.profile?.minors
        ? planLater.profile.minors
            .flatMap((minor) => minor.examComponents)
            .flatMap((examComponent) => examComponent.courses)
            .find((course) => course.code === courseCode)
        : undefined;
    }
    const replacementCourse = planLater.replacements?.find((course) => course.code === courseCode);
    return (
      mandatoryCourse ||
      mandatoryChoiceCourse ||
      specialisationCourse ||
      existingOtherCourse ||
      profileCourse ||
      minorCourse ||
      replacementCourse
    );
  }

  isCourseAddedToPlanLater(courseData: CourseData): boolean {
    return (
      !courseData.isPlannedCourse &&
      (this.isCourseInPlanLaterOtherCourses(courseData.code) || this.isCourseInPlanLaterMandatory(courseData.code))
    );
  }

  public isCourseInPlanLaterOtherCourses(courseCode: string): boolean {
    const planLater = this.planStateService.planLater();

    return planLater?.otherCourses?.some((course) => course.code === courseCode) ?? false;
  }

  private isCourseInPlanLaterMandatory(courseCode: string): boolean {
    const planLater = this.planStateService.planLater();

    return (
      (planLater?.mandatory
        ?.flatMap((examComponent) => examComponent.courses)
        .some((course) => course.code === courseCode) ??
        false) ||
      (planLater?.mandatoryChoice
        ?.flatMap((examComponent) => examComponent.courses)
        .some((course) => course.code === courseCode) ??
        false)
    );
  }

  private updateCourses(examComponent: ExamComponent, updatedCourse: Course, selectedPeriod?: SelectedPeriod): void {
    examComponent.courses
      .filter((course) => course.code === updatedCourse.code)
      .forEach((course) => {
        return this.updateCourse(course, updatedCourse, selectedPeriod);
      });
  }

  private updateCourse(course: Course, updatedCourse: Course, selectedPeriod?: SelectedPeriod): void {
    course.planLater = updatedCourse.planLater;
    if (selectedPeriod) {
      course.yearNr = selectedPeriod.year.yearNr;
      course.periodDescription = this.courseHelper.stripPeriodDescriptionNumber(
        selectedPeriod.period.periodDescription,
      );
    } else {
      // Course is removed from plan
      course.yearNr = 0;
      course.periodNr = 0;
      course.periodDescription = "";
    }
  }

  private removeCourseFromCourses(courses: Course[], courseData: CourseData): Course[] {
    return courses.filter((course) => !this.courseHelper.isSameCourse(course, courseData));
  }
}
